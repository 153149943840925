<template>
  <v-footer
    :absolute="true"
    :fixed="false"
    dark
    padless
    style="z-index: 9999"
    app>
    <v-card class="flex" flat tile>
      <v-card-title :style="ghmFooterBkColor">
        <strong class="subheading">{{ $t("footer.getConnectedMSg") }}</strong>
          <v-row
            align="center"
            justify="center"
            class="buildVersionCss" v-if="isAdminUser">
            <v-col cols="12" md="6">
              UI Version:
              [{{ getBuildInformation.packageVersion }}-{{ getBuildInformation.packageBuildDate }}]
               / Backend Version:
              [{{ getBackendBuildVersionInfo.version }}-{{
                getBackendBuildVersionInfo.time | format_Date_dd_mm_yyyy }}]
            </v-col>
          </v-row>
          <v-spacer v-if="!isAdminUser"></v-spacer>
        <div class="text-center">
          <v-bottom-sheet v-model="sheet" inset max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-for="icon in icons"
                :key="icon"
                class="mx-4"
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="24px">{{ icon }}</v-icon>
              </v-btn>
            </template>
            <v-sheet height="200px">
              <div class="d-flex flex-row-reverse">
                <v-btn class="mt-4" text color="error" @click="sheet = !sheet">
                  {{ $t("app.button.close") }}
                </v-btn>
              </div>
              <v-row class="text-center">
                <v-col cols="12" sm="8" md="12">
                  <div class="my-3">
                    GHM Dataportal Administrator Contact
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6">
                        <v-icon>mdi-email</v-icon>
                        <v-icon>mdi-at</v-icon> ServiceDesk@ghm.de
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12" md="6">
                        <v-icon>mdi-phone</v-icon> <v-icon>mdi-at</v-icon> +49
                        89 189149 999
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
          </v-bottom-sheet>
        </div>
      </v-card-title>
      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} —
        <strong>© GHM Gesellschaft für Handwerksmessen mbH</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
import Constants from '@/constants';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      icons: ['fas fa-address-card'],
      sheet: false,
      APP_FOOTER_BAR_BACKGROUND_COLOR:
        Constants.APP_FOOTER_BAR_BACKGROUND_COLOR,
    };
  },
  computed: {
    ...mapGetters([
      'getBuildInformation',
      'getBackendBuildVersionInfo',
      'isAdminUser',
    ]),

    /**
     * GHM Footer background color.
     */
    ghmFooterBkColor() {
      return {
        'background-color': `${this.APP_FOOTER_BAR_BACKGROUND_COLOR} !important`,
        'border-color': `${this.APP_FOOTER_BAR_BACKGROUND_COLOR} !important`,
      };
    },
  },
};
</script>
<style scoped>
.buildVersionCss {
  font-family: "Lucida Sans Un icode", "Lucida Grande", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  word-spacing: 2px;
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  font-variant: small-caps;
  text-transform: capitalize;
}
</style>
