const APPLICATION_ROLE_ADMIN = 'ROLE_ADMIN';
const APPLICATION_ROLE_USER = 'ROLE_USER';
const GLOBAL_EVENT_BUS_FOR_SNACK_BAR = 'showSnackBarMsg';
const GLOBAL_EVENT_LOGOUT_EMITTER_EVENT = 'logoutGlobalEmitterEvent';

const GHM_LOCAL_SESSION_STORAGE_NAME = 'dataPortalStore';

// Application Colors
const APP_BAR_BACKGROUND_COLOR = 'white';
const APP_SYSTEM_BAR_BACKGROUND_COLOR = '#002f4d'; // '#004670';
const APP_FOOTER_BAR_BACKGROUND_COLOR = '#002f4d'; // '#004670';
const APP_MENU_TEXT_AND_ICON_COLOR = '#004670';
const APP_NAVIGATION_DRAWER_TEXT_AND_ICON_COLOR = 'white';

// APP Menu
const APP_MENU_UNIQUE_ID_NAME_FOR_QUERIES = 'menu-name-queries';
const APP_MENU_UNIQUE_ID_NAME_FOR_MANAGEMENT = 'menu-name-management';

// DataTable types
const APP_DATATABLE_TYPE_DATA_LIST = 'app-datatable-type-data-list';
const APP_DATATABLE_TYPE_USER_LIST = 'app-datatable-type-user-list';
const APP_DATATABLE_TYPE_ADMIN_LIST = 'app-datatable-type-admin-list';
const APP_DATATABLE_TYPE_ADMIN_QUERY_LIST = 'app-datatable-type-admin-query-list';
const APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW = 'app-datatable-type-admin-data-query-view';
const APP_DATATABLE_TYPE_USER_DATA_QUERY_VIEW = 'app-datatable-type-user-data-query-view';
const APP_DATATABLE_TYPE_ACTION_EDIT_DELETE = 'app-datatable-type-action-edit-delete';

// Admin User Management Tabs
const ADMIN_USER_MANAGEMENT_USER_TAB = 'USER_TAB';
const ADMIN_USER_MANAGEMENT_ROLE_TAB = 'ROLE_TAB';

const USER_LIST_ACTION_TYPE_CSV_DOWNLOAD = 'csvDownloadAction';
const USER_LIST_ACTION_TYPE_JSON_VIEW = 'jsonViewdAction';

// AXIOS rest api URLs
// User login
const GHM_LOGIN_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/login/user/signin`;

/* ADMIN - START */

const GHM_ADMIN_ADD_ROLE_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/add/applicationRole`;
const GHM_ADMIN_UPDATE_ROLE_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/update/applicationRole`;
const GHM_ADMIN_FIND_ALL_AVAILABLE_ROLES_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/applicationRoles`;
const GHM_ADMIN_FIND_ALL_AVAILABLE_ROLES_FOR_USER_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/user/applicationRoles`;
const GHM_ADMIN_FIND_ALL_AVAILABLE_USERS_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/get/allUsers`;
const GHM_ADMIN_FIND_ALL_DATA_QUERIES_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/get/allDataQueries`;
const GHM_ADMIN_FIND_DATA_QUERY_DETAILS_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/get/getDataQueryDetails`;
const GHM_ADMIN_FIND_ALL_AVAILABLE_DATA_SOURCES_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/query/getAllDataSources`;
const GHM_ADMIN_DELETE_ROLE_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/delete/applicationRole`;
const GHM_ADMIN_DELETE_DATA_QUERY_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/delete/dataQuery`;
const GHM_ADMIN_DELETE_USER_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/delete/user`;
const GHM_ADMIN_ADD_USER_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/add/user`;
const GHM_ADMIN_ADD_DATA_QUERY_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/add/dataQuery`;
const GHM_ADMIN_UPDATE_USER_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/update/user`;
const GHM_ADMIN_UPDATE_DATA_QUERY_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/update/dataQuery`;
const GHM_ADMIN_GET_MS_SQL_DATABASES_DETTAIL_LIST = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/get/databaseList`;
const GHM_ADMIN_GET_USER_DETAILS_FROM_INVITE_LINK_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/login/user/invitationToken`;
const GHM_ADMIN_GET_USER_SIGNUP_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/login/user/signup`;
const GHM_ADMIN_GET_USER_RESET_PASSWORD_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/login/user/resetPassword`;
const GHM_USER_FIND_ALL_DATA_QUERIES_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/user/get/allDataQueries`;
const GHM_UNGERBOECK_API_FETCH_DATA_QUERIES_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/ungerboeckApi/get/queryDetails`;
const GHM_UNGERBOECK_API_FETCH_BASE_URL_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/ungerboeckApi/get/baseUrls`;
const GHM_UNGERBOECK_API_FETCH_NEXT_OR_PREVIOUS_REST_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/ungerboeckApi/fetch/nextPrevious`;
const GHM_ADMIN_FIND_ALL_AVAILABLE_APPLICATION_LOGS_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/get/applicationLogs`;
const GHM_ADMIN_FIND_TOTAL_COUNT_OF_APPLICATION_LOGS_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/get/applicationLogs/totalCount`;
const GHM_ADMIN_GET_BACKEND_BUILD_INFORMATION_API_URL = `${process.env.VUE_APP_GHM_REST_API_CONTEXT_ROOT}/admin/build/version`;
/* ADMIN - END */

/* Events Start */
const GHM_DATA_TABLE_DELETE_ITEM_EVENT = 'GhmDataTAbleDeleteItemEvent';

/* Query String Type */
const APP_ADMIN_QUERY_TYPE_SQL = 'SQL';
const APP_ADMIN_QUERY_TYPE_JSON = 'JSON';

/* Secret key for crypto alogrithm */

const GHM_CRYPTO_SECRET_KEY = 'XSWLKJL1234qaswzxdertyghbfvploUUUUxNHJUYHGSNjwjjwnnw7762eqxJ^JMMMMczv9i938473628jjdjsdshjhkskd66252526278dhndnxxjsshshhd';

/* Events End */
export default {
  APPLICATION_ROLE_ADMIN,
  APPLICATION_ROLE_USER,
  GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
  GLOBAL_EVENT_LOGOUT_EMITTER_EVENT,
  APP_BAR_BACKGROUND_COLOR,
  APP_SYSTEM_BAR_BACKGROUND_COLOR,
  APP_FOOTER_BAR_BACKGROUND_COLOR,
  APP_MENU_TEXT_AND_ICON_COLOR,
  GHM_LOGIN_REST_API_URL,
  APP_MENU_UNIQUE_ID_NAME_FOR_QUERIES,
  APP_MENU_UNIQUE_ID_NAME_FOR_MANAGEMENT,
  APP_DATATABLE_TYPE_DATA_LIST,
  APP_DATATABLE_TYPE_USER_LIST,
  APP_DATATABLE_TYPE_ADMIN_LIST,
  APP_DATATABLE_TYPE_ADMIN_DATA_QUERY_VIEW,
  APP_NAVIGATION_DRAWER_TEXT_AND_ICON_COLOR,
  ADMIN_USER_MANAGEMENT_USER_TAB,
  ADMIN_USER_MANAGEMENT_ROLE_TAB,
  APP_DATATABLE_TYPE_ACTION_EDIT_DELETE,
  GHM_ADMIN_ADD_ROLE_REST_API_URL,
  GHM_ADMIN_FIND_ALL_AVAILABLE_ROLES_REST_API_URL,
  GHM_DATA_TABLE_DELETE_ITEM_EVENT,
  GHM_ADMIN_DELETE_ROLE_REST_API_URL,
  GHM_ADMIN_UPDATE_ROLE_REST_API_URL,
  GHM_ADMIN_FIND_ALL_AVAILABLE_USERS_REST_API_URL,
  GHM_ADMIN_FIND_ALL_AVAILABLE_ROLES_FOR_USER_REST_API_URL,
  GHM_ADMIN_ADD_USER_REST_API_URL,
  GHM_ADMIN_UPDATE_USER_REST_API_URL,
  GHM_ADMIN_GET_USER_DETAILS_FROM_INVITE_LINK_API_URL,
  GHM_ADMIN_GET_USER_SIGNUP_API_URL,
  GHM_ADMIN_DELETE_USER_REST_API_URL,
  GHM_LOCAL_SESSION_STORAGE_NAME,
  GHM_CRYPTO_SECRET_KEY,
  APP_DATATABLE_TYPE_ADMIN_QUERY_LIST,
  GHM_ADMIN_FIND_ALL_AVAILABLE_DATA_SOURCES_REST_API_URL,
  APP_ADMIN_QUERY_TYPE_SQL,
  APP_ADMIN_QUERY_TYPE_JSON,
  GHM_ADMIN_ADD_DATA_QUERY_REST_API_URL,
  GHM_ADMIN_FIND_ALL_DATA_QUERIES_REST_API_URL,
  GHM_ADMIN_FIND_DATA_QUERY_DETAILS_REST_API_URL,
  GHM_ADMIN_DELETE_DATA_QUERY_REST_API_URL,
  GHM_ADMIN_UPDATE_DATA_QUERY_REST_API_URL,
  APP_DATATABLE_TYPE_USER_DATA_QUERY_VIEW,
  GHM_USER_FIND_ALL_DATA_QUERIES_REST_API_URL,
  GHM_UNGERBOECK_API_FETCH_DATA_QUERIES_REST_API_URL,
  GHM_UNGERBOECK_API_FETCH_BASE_URL_REST_API_URL,
  GHM_UNGERBOECK_API_FETCH_NEXT_OR_PREVIOUS_REST_API_URL,
  USER_LIST_ACTION_TYPE_CSV_DOWNLOAD,
  USER_LIST_ACTION_TYPE_JSON_VIEW,
  GHM_ADMIN_GET_MS_SQL_DATABASES_DETTAIL_LIST,
  GHM_ADMIN_FIND_ALL_AVAILABLE_APPLICATION_LOGS_API_URL,
  GHM_ADMIN_FIND_TOTAL_COUNT_OF_APPLICATION_LOGS_API_URL,
  GHM_ADMIN_GET_USER_RESET_PASSWORD_API_URL,
  GHM_ADMIN_GET_BACKEND_BUILD_INFORMATION_API_URL,
};
