<template>
  <v-flex>
    <v-card :elevation="15">
      <h1 class="text-left" style="padding: 10px 0 0 10px;">{{ $t('forgotRestPassword') }}</h1>
      <h4 class="text-left signUp-subTitle">
       {{ $t('forgotPasswordMsg2') }}</h4>
      <v-card-text>
        <v-form v-model="isValid">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                :label="$t('userNameOrEmailId')"
                :name="$t('userNameOrEmailId')"
                v-model="userNameOrEmailId"
                prepend-icon="verified_user"
                type="text"
                required
                maxlength="100"
                 :rules="userNameOrEmailRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          elevation="23"
          large
          color="primary"
          :disabled="!isValid"
          @click="onClickOfForgotPassword"
        >Reset Password</v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import Constants from '@/constants';
import { mapActions } from 'vuex';

export default {

  data() {
    return {
      isValid: true,
      userNameOrEmailId: '',
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
    };
  },

  computed: {

    /**
     * Computed function for user Name rules.
     */
    userNameOrEmailRules() {
      return [
        (value) => !!value || this.$t('userValidations.userNameOrEmailIdIsRequired'),
        (value) => ((value && value.indexOf('@') !== -1) ? (value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) || this.$t('userValidations.invalidEmailAddress')) : true),
        (value) => (value && value.length >= 3)
          || this.$t('userValidations.emailMustHaveCharacter'),
        (value) => (value && value.length <= 100)
          || this.$t('userValidations.emailMaxCharacter'),
      ];
    },

  },

  methods: {

    ...mapActions(['resetPassword']),

    /**
     * On Click of Forgot password.
     */
    async onClickOfForgotPassword() {
      console.log('onClickOfForgotPassword : ', this.userNameOrEmailId);
      const isPasswordReset = await this.resetPassword({
        userNameOrEmailId: this.userNameOrEmailId,
        lang: this.$i18n.locale,
      });
      console.log('isPasswordReset : ', isPasswordReset);
      if (isPasswordReset && Object.prototype.hasOwnProperty.call(isPasswordReset, 'successful') && isPasswordReset.successful) {
        this.$eventBus.$emit(this.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
          { successful: true, message: this.$t('forgotPasswordResetSuccessfully') });
      } else {
        this.$eventBus.$emit(this.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
          { successful: false, message: this.$t('forgotPasswordResetFailure') });
      }
    },

  },
};
</script>

<style>

.signUp-subTitle {
  color: gray;
  font-size: 18px;
  font-weight: normal;
  padding: 10px 0 0 10px;
}

.signUp-label {
  color: gray;
  font-size: 18px;
  padding-left: 0;
}
</style>
