import Constants from '@/constants';
import ApiService from '@/services/ApiService';

const CryptoJS = require('crypto-js');

/**
 * Method to set local storage.
 *
 * @param {*} state
 */
export function setLocalStorage(state) {
  console.log('----------- local storage set : ', state);
  localStorage.setItem(
    Constants.GHM_LOCAL_SESSION_STORAGE_NAME,
    CryptoJS.AES.encrypt(JSON.stringify(state), Constants.GHM_CRYPTO_SECRET_KEY).toString(),
  );
}

/**
 * Method to get local storage.
 */
export function getLocalStorage() {
  console.log('----------- local storage get : ');
  if (localStorage.getItem(Constants.GHM_LOCAL_SESSION_STORAGE_NAME)) {
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem(Constants.GHM_LOCAL_SESSION_STORAGE_NAME),
      Constants.GHM_CRYPTO_SECRET_KEY,
    );
    const localStorageState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log('------localStorageState 1-----: ', localStorageState);
    console.log('------localStorageState 2-----: ', localStorageState.user.accessToken);
    ApiService.setAxiosHeader(localStorageState.user.accessToken,
      localStorageState.user.loggedInUserData.ipAddress);
    return localStorageState;
  }
  return null;
}
