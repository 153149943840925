import Vue from 'vue';
import i18n from '@/plugins/i18n';
import ApiService from '@/services/ApiService';
import JsonViewer from 'vue-json-viewer';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/Filters';

Vue.config.productionTip = false;

// Global Event Bus
Vue.prototype.$eventBus = new Vue();

// Json Viewer plugin
Vue.use(JsonViewer);

ApiService.init(process.env.VUE_APP_API_ENDPOINT);

ApiService.setGlobalExceptionResponseHandler(Vue.prototype.$eventBus);

router.beforeEach((to, from, next) => {
  let language = to.params.lang;

  if (!language) {
    language = 'de';
  }
  i18n.locale = language;
  next();
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
