<template>
  <div id="app">
    <v-app>
     <!-- Progressbar Component START -->
      <v-overlay :value="loader" z-index="9999">
        <v-progress-circular :size="100" :width="10" color="yellow" indeterminate>
        </v-progress-circular>
      </v-overlay>
      <!--Progressbar Component END -->

      <!-- Dashboard Component START -->
      <Dashboard v-if="loggedIn" />
      <!-- Dashboard Component END -->
      <!-- HOME Component START -->
      <Home v-if="!loggedIn" />
      <!-- HOME Component END -->
      <!-- Global Snackbar Component START -->
      <Snackbar
        :multiline="true"
        :snackbar.sync="isSnackbarVisible"
        :text="snackMsg"
        :isSuccess="isActionSuccessful"
        @changeVisibilityAfterTimeout="isSnackbarVisible = false"
      ></Snackbar>

      <!-- Global Snackbar Component START -->
    </v-app>
  </div>
</template>

<script>
import Home from '@/components/home/Home.vue';
import Dashboard from '@/components/dashboard/Dashboard.vue';
// import AppFooter from '@/components/app/AppFooter.vue';
import Snackbar from '@/components/app/Snackbar.vue';
import Constants from '@/constants';
import { mapGetters, mapActions } from 'vuex';
import { getLocalStorage, setLocalStorage } from '@/plugins/LocalStorage';

export default {
  name: 'App',

  components: {
    Home,
    // AppFooter,
    Snackbar,
    Dashboard,
  },

  data() {
    return {
      isActionSuccessful: false,
      isSnackbarVisible: false,
      snackMsg: '',
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
    };
  },

  /**
   * Register snackbar global EventBus when main App component is created.
   */
  created() {
    this.$eventBus.$on(
      this.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
      this.showSnackbar.bind(this),
    );

    const ghmState = getLocalStorage();
    console.log('====== >>>> here i am reading 11111 ==== ', ghmState);
    if (ghmState) {
      console.log('====== >>>> here i am reading 11111 ==== ');
      if (ghmState.user.accessToken && ghmState.user.loggedInUserData) {
        console.log('====== >>>> all data exist ==== ');
        this.restoreParentStateFromSessionStore(
          ghmState,
        );
        this.restoreStateFromSessionStorage(
          ghmState.user,
        );
        console.log('====== ghmState.isDarkModeState ==== ', ghmState.isDarkModeState);
        this.$vuetify.theme.dark = ghmState.isDarkModeState;
      }
    }
    // Set localstorage when user refresh the page.
    window.addEventListener('beforeunload', () => {
      console.log('====== reload event =====', JSON.stringify(this.$store.state));
      if (this.$store.state && this.$store.state.user.accessToken
                            && this.$store.state.user.loggedInUserData) {
        console.log('====== >>>> all data exist ==== ');
        this.$store.state.loader = false;
        setLocalStorage(this.$store.state);
      }
    });
  },
  /**
   * De-Register snackbar global EventBus when App component destroyed / application killed.
   */
  beforeDestroy() {
    console.log('*************** Before destroy ********************');
    this.$eventBus.$off(this.GLOBAL_EVENT_BUS_FOR_SNACK_BAR);
  },
  computed: {
    ...mapGetters(['authenticating', 'loggedIn', 'loader']),
  },
  methods: {

    ...mapActions(['restoreParentStateFromSessionStore', 'restoreStateFromSessionStorage']),

    /**
     * Method which handled applicaton global snackbar.
     */
    showSnackbar($event) {
      console.log(
        '------------------ show snack bar ------------------- > ',
        this.isSnackbarVisible,
      );
      this.isSnackbarVisible = true;
      if (typeof $event !== 'undefined') {
        if ($event.successful) {
          this.isActionSuccessful = true;
          this.snackMsg = $event.message;
        } else {
          console.log(
            '------------------ show snack bar FAilure ------------------- > ',
          );
          this.isActionSuccessful = false;
          this.snackMsg = $event.message;
        }
      } else {
        this.isActionSuccessful = false;
        this.snackMsg = 'Some Error occurred !';
      }
    },
  },
};
</script>
<style scoped lang="scss">
  @import "@/styles/Variables.scss";
</style>
