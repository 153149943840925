/* eslint no-shadow: ["error", { "allow": ["state"] }] */

// import router from '@/router';
import { LoginService } from '@/services/login/LoginService';
import i18n from '@/plugins/i18n';
import Constants from '@/constants';

const state = {
  authenticating: false,
  accessToken: '',
  authenticationErrorCode: 0,
  authenticationError: '',
  backendBuildVersionInfo: {},
  loggedInUserData: {
    userName: '',
    emailId: '',
    role: [],
    ipAddress: '',
  },
};

const getters = {

  loggedIn: () => (!!state.accessToken),

  authenticationErrorCode: () => state.authenticationErrorCode,

  authenticationError: () => state.authenticationError,

  authenticating: () => state.authenticating,

  getAccessToken: () => state.accessToken,

  getLoggedInUserData: () => state.loggedInUserData,

  isAdminUser: () => {
    const adminRole = (state.loggedInUserData.role)
      .filter((role) => role === Constants.APPLICATION_ROLE_ADMIN);
    return !!((adminRole && adminRole.length > 0));
  },

  getLoggedInUserRoles: () => state.loggedInUserData.role,

  getBackendBuildVersionInfo: () => state.backendBuildVersionInfo,

};

const mutations = {
  loginRequest(state) {
    state.authenticating = true;
    state.authenticationError = '';
    state.authenticationErrorCode = 0;
  },

  loginSuccess(state, loginData) {
    state.accessToken = loginData.access_token;
    state.authenticating = false;
    state.authenticationError = '';
    state.authenticationErrorCode = 0;
    state.loggedInUserData.userName = loginData.userName;
    state.loggedInUserData.emailId = loginData.emailId;
    state.loggedInUserData.role = loginData.rolesList;
    state.loggedInUserData.ipAddress = loginData.ipAddress;
  },

  restoreStateFromSessionStore(state, loginData) {
    console.log('-----Mutation : User:  restoreStateFromSessionStore : ', loginData);
    state.accessToken = loginData.accessToken;
    state.authenticating = false;
    state.authenticationError = '';
    state.authenticationErrorCode = 0;
    state.loggedInUserData.userName = loginData.loggedInUserData.userName;
    state.loggedInUserData.emailId = loginData.loggedInUserData.emailId;
    state.loggedInUserData.role = loginData.loggedInUserData.role;
  },

  loginError(state, { errorCode, errorMessage }) {
    state.accessToken = '';
    state.authenticating = false;
    state.authenticationErrorCode = errorCode;
    state.authenticationError = errorMessage;
    state.loggedInUserData.userName = '';
    state.loggedInUserData.emailId = '';
    state.loggedInUserData.role = [];
  },
  logoutSuccess(state) {
    state.accessToken = '';
    state.authenticating = false;
    state.authenticationErrorCode = 0;
    state.authenticationError = '';
    state.loggedInUserData.userName = '';
    state.loggedInUserData.role = [];
  },

  setBackendVersionInfomation(state, buildInfoObject) {
    state.backendBuildVersionInfo = buildInfoObject;
  },

};

const actions = {

  /**
   * Login method to validate username and password, if successful returns jwt token.
   *
   * @param {*} param userName
   * @param {*} param password
   */
  async login({ commit }, { userName, password }) {
    console.log('Action: login : start : ', userName, ' : ', password);
    commit('loadingRequest');
    commit('loginRequest');
    try {
      console.log('Action: login : calling service : ', userName, ' : ', password);
      const loginData = await LoginService.login(userName, password);
      console.log('Action: login : AFter service : ', loginData);
      if (loginData && loginData.access_token) {
        commit('loginSuccess', loginData);
        const buildInfoVersionResponse = await LoginService.getBuildVersionInfo();
        console.log('**** buildInfoVersionResponse ** ', buildInfoVersionResponse);
        commit('setBackendVersionInfomation', buildInfoVersionResponse);
        commit('unLoadingRequest');
        console.log('loader ended.....');
        return true;
      }
      console.log('Else part ....');
      commit('loginError', { errorCode: 401, errorMessage: i18n.t('errorMessages.wrongUserNameAndPasswordMsg') });
      commit('unLoadingRequest');
      return false;
    } catch (e) {
      console.log('----------------- login exception ------------------: ', e.message);
      if (e instanceof Error) {
        commit('unLoadingRequest');
        commit('loginError', { errorCode: e.errorCode, errorMessage: i18n.t('errorMessages.wrongUserNameAndPasswordMsg') });
      }
      return false;
    }
  },

  /**
   * Get build information
   *
   */
  async getBuildVersionInfo({ commit }) {
    console.log('Action: get build version info : ');
    try {
      console.log('User Store: Reset Password : calling service : ');
      const response = await LoginService.getBuildVersionInfo();
      console.log('User Store: backend : build info : ', response);
      if (response) {
        commit('backendBuildVersionInfo', response);
      }
      return response;
    } catch (e) {
      console.log('----------------- login exception ------------------: ', e.message);
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: 'Build Information Error' });
      }
      return false;
    }
  },

  /**
   * Reset password
   *
   * @param {*} param userNameOrEmailId: Username or Email id
   */
  async resetPassword({ commit }, { userNameOrEmailId, lang }) {
    console.log('Action: resetPassword : start : ', userNameOrEmailId);
    commit('loadingRequest');
    try {
      console.log('User Store: Reset Password : calling service : ', userNameOrEmailId);
      const resetPassword = await LoginService.resetPassword(userNameOrEmailId, lang);
      console.log('User Store: Reset Password : AFter service : ', resetPassword);
      commit('unLoadingRequest');
      return resetPassword;
    } catch (e) {
      console.log('----------------- login exception ------------------: ', e.message);
      if (e instanceof Error) {
        commit('unLoadingRequest');
        commit('loginError', { errorCode: e.errorCode, errorMessage: i18n.t('errorMessages.wrongUserNameAndPasswordMsg') });
      }
      return false;
    }
  },

  /**
   *
   * @param {*} commit
   * @param {*} signUpData
   */
  async signUp({ commit }, { signUpData, invitationToken }) {
    try {
      commit('loadingRequest');
      console.log('-------------->>> invitationToken <<<<<<----------------- ', invitationToken, ' : ', signUpData);
      const signUpResponse = await LoginService.signUp(signUpData, invitationToken);
      commit('unLoadingRequest');
      return signUpResponse;
    } catch (e) {
      if (e instanceof Error) {
        commit('SignUpError', { errorCode: e.errorCode, errorMessage: e.message });
      }

      return false;
    }
  },
  /**
   * Create User By Admin
   *
   * @param {*} commit
   * @param {*} signUpData
   */
  async createUserByAdmin({ commit }, signUpData) {
    try {
      console.log('-------Action: createUserByAdmin--------', this.signUpData);
      commit('loadingRequest');
      const userCreatedResponse = await LoginService.createUserByAdmin(signUpData);
      commit('unLoadingRequest');
      return userCreatedResponse;
    } catch (e) {
      if (e instanceof Error) {
        commit('SignUpError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      commit('unLoadingRequest');
      return false;
    }
  },

  logout({ commit }) {
    console.log('****** logout user store ***********');
    LoginService.logout();
    commit('logoutSuccess');
    // router.push('/');
  },

  /**
   * Validate mobile OTP Action
   *
   * @param {*} commit
   * @param {*} invitationToken
   */
  async findUserFromInvitationToken({ commit }, invitationToken) {
    try {
      commit('loadingRequest');
      const reponse = await LoginService.findUserFromInvitationToken(invitationToken);
      commit('unLoadingRequest');
      return reponse;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('SignUpError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return false;
    }
  },

  restoreStateFromSessionStorage({ commit }, loginData) {
    console.log('-----setting up state : ', loginData);
    commit('restoreStateFromSessionStore', loginData);
    console.log('-----setting up successful : ', state);
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
