import Constant from '@/constants';
import ApiService from '../ApiService';

const AdminService = {

  /**
   * Get all application roles for Admin.
   *
   * @returns Page object for role list.
   * */
  async findAllRoles() {
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_FIND_ALL_AVAILABLE_ROLES_REST_API_URL,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findAllRoles >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching all roles', error);
    }
    return [];
  },

  /**
   * Get all application roles for user.
   *
   * @returns Page object for role list.
   * */
  async findAllRolesForUser() {
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_FIND_ALL_AVAILABLE_ROLES_FOR_USER_REST_API_URL,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findAllRolesForUser >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching all roles', error);
    }
    return [];
  },

  /**
   * Get all users for Admin.
   *
   * @returns Page object for user list.
   * */
  async findAllUsersForAdmin() {
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_FIND_ALL_AVAILABLE_USERS_REST_API_URL,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findAllUsersForAdmin >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching all users for admin', error);
    }
    return [];
  },

  /**
   * Get all data queries for Admin.
   *
   * @returns Page object for user list.
   * */
  async findAllDataQueriesList() {
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_FIND_ALL_DATA_QUERIES_REST_API_URL,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findAllDataQueriesList >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching all data queries for admin', error);
    }
    return [];
  },

  /**
   * Get data query detail for Admin.
   *
   * @returns Page object for user list.
   * */
  async findQueryDetailForAdmin(dataQueryId) {
    const requestData = {
      method: 'GET',
      url: `${Constant.GHM_ADMIN_FIND_DATA_QUERY_DETAILS_REST_API_URL}/${dataQueryId}`,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findQueryDetailForAdmin >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching data query details for admin', error);
    }
    return [];
  },

  /**
   * Get data query detail from Rest URL.
   *
   * @returns Page object for user list.
   * */
  async fetchPaginationMetaData(restUrl, dataQueryIdForApi) {
    console.log('---------------- fetchPaginationMetaData ------------- :', restUrl, ' : ', dataQueryIdForApi);
    const requestData = {
      method: 'GET',
      url: Constant.GHM_UNGERBOECK_API_FETCH_DATA_QUERIES_REST_API_URL,
      params: {
        url: restUrl,
        dataQueryIdForApi,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin fetchPaginationMetaData >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching data query details from REST API', error);
    }
    return {};
  },

  /**
   * Get all available datasources.
   *
   * @returns Page object for datasource list.
   * */
  async fetchAllAvailableDataSourcesList() {
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_FIND_ALL_AVAILABLE_DATA_SOURCES_REST_API_URL,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin fetchAllAvailableDataSourcesList >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.log('Admin: Error while fetching all users for admin', error);
    }
    return [];
  },

  /**
   * Get all available datasources details list.
   *
   * @returns Page object for datasource list.
   * */
  async findDBDatasourceDetailList() {
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_GET_MS_SQL_DATABASES_DETTAIL_LIST,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findDBDatasourceDetailList >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.log('Admin: Error while fetching all DB datasource details for admin', error);
    }
    return [];
  },

  /**
   * Get list of all application logs.
   *
   * @returns list of appliation logs
   * */
  async findAllApplicationLogs(paginationData) {
    console.log('======== findTotalCountForApplicationLogs ===== ', paginationData);
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_FIND_ALL_AVAILABLE_APPLICATION_LOGS_API_URL,
      params: paginationData,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findAllApplicationLogs >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.log('Admin: Error while fetching all application logs for admin', error);
    }
    return [];
  },

  /**
   * Get total count of application logs
   *
   * @returns list of appliation logs
   * */
  async findTotalCountForApplicationLogs() {
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_FIND_TOTAL_COUNT_OF_APPLICATION_LOGS_API_URL,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findTotalCountForApplicationLogs >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.log('Admin: Error while fetching total count for application logs', error);
    }
    return [];
  },

  /**
   * Add Application role by admin.
   *
   * @returns boolean.
   * */
  async addApplicationRole(roleName) {
    const requestData = {
      method: 'POST',
      url: Constant.GHM_ADMIN_ADD_ROLE_REST_API_URL,
      data: {
        roleName,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin add role >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while adding appliation role >>', error);
    }
    return {};
  },

  /**
   * Add new user by admin.
   *
   * @returns boolean.
   * */
  async addNewUser(user) {
    const requestData = {
      method: 'POST',
      url: `${Constant.GHM_ADMIN_ADD_USER_REST_API_URL}/${user.lang}`,
      data: user,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin add user >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while adding user >>', error);
    }
    return {};
  },

  /**
   * Add new data query by admin.
   *
   * @returns boolean.
   * */
  async addNewDataQuery(dataQueryItem) {
    const requestData = {
      method: 'POST',
      url: `${Constant.GHM_ADMIN_ADD_DATA_QUERY_REST_API_URL}`,
      data: dataQueryItem,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin add new data query >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while adding new data query >>', error);
    }
    return {};
  },

  /**
   * Add new user by admin.
   *
   * @returns boolean.
   * */
  async updateUser(user) {
    console.log('------- AdminService: udateUser : ', user);
    const requestData = {
      method: 'PUT',
      url: `${Constant.GHM_ADMIN_UPDATE_USER_REST_API_URL}/${user.lang}/${user.id}`,
      data: user,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin update user >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while updating user >>', error);
    }
    return {};
  },

  /**
   * Update data query by admin.
   *
   * @returns boolean.
   * */
  async updateDataQuery(dataQueryItem) {
    const requestData = {
      method: 'PUT',
      url: `${Constant.GHM_ADMIN_UPDATE_DATA_QUERY_REST_API_URL}/${dataQueryItem.id}`,
      data: dataQueryItem,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin update data query >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while updating data query >>', error);
    }
    return {};
  },

  /**
   * Update Application role by admin.
   *
   * @returns boolean.
   * */
  async updateApplicationRole(id, roleName) {
    const requestData = {
      method: 'PUT',
      url: Constant.GHM_ADMIN_UPDATE_ROLE_REST_API_URL,
      data: {
        id,
        roleName,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin update role >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while updating appliation role >>', error);
    }
    return {};
  },

  /**
   * Admin: Delete application role.
   *
   * @returns id.
   * */
  async deleteApplicationRole(id) {
    console.log('------------deleteApplicationRole -------', id);
    const requestData = {
      method: 'DELETE',
      url: `${Constant.GHM_ADMIN_DELETE_ROLE_REST_API_URL}/${id}`,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** AdminService : deleteApplicationRole : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin Service: Error while deleting application role :', error);
    }
    return {};
  },

  /**
   * Admin: Delete Data Querys.
   *
   * @returns id.
   * */
  async deleteDataQueryByAdmin(id) {
    console.log('------------AdminService: deleteDataQueryByAdmin -------', id);
    const requestData = {
      method: 'DELETE',
      url: `${Constant.GHM_ADMIN_DELETE_DATA_QUERY_REST_API_URL}/${id}`,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** AdminService : deleteDataQueryByAdmin : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin Service: Error while deleting DATA QUERY :', error);
    }
    return {};
  },

  /**
   * Admin: Delete user role.
   *
   * @returns id.
   * */
  async deleteUserByAdmin(id) {
    console.log('------------deleteUserByAdmin -------', id);
    const requestData = {
      method: 'DELETE',
      url: `${Constant.GHM_ADMIN_DELETE_USER_REST_API_URL}/${id}`,
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** AdminService : deleteUserByAdmin : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin Service: Error while deleting user :', error);
    }
    return {};
  },

};

export default AdminService;
