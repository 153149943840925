<template>
  <v-form v-model="isValid">
    <v-container fill-height>
     <v-row class="flex-nowrap" no-gutters>
      <v-col>
          <v-text-field
            :label="$t('loginUserName')"
            :name="$t('loginUserName')"
            v-model="loginData.userName"
            prepend-icon="person"
            type="text"
            maxlength="30"
            style="width:  260px"
            :rules="userNameRules"
          ></v-text-field>
      </v-col>
         <v-col>
          <v-text-field
            id="password"
            :label="$t('loginPassword')"
            :name="$t('loginPassword')"
            v-model="loginData.password"
            prepend-icon="lock"
            type="password"
            style="width: 260px"
            :rules="passwordRules"
          ></v-text-field>
          <header class="forgot-text-css">
             <v-btn
              text
              color="primary" @click="onClickOfForgotPassword">
                Forgot Password ?
            </v-btn>
          </header>
         </v-col>
         <v-col align-self-right>
          <v-btn class="ml-4 mt-n2" color="teal"
          medium :disabled="!isValid" @click="handleSubmit">
            {{ $t('loginIn') }}
            <v-icon right dark>mdi-login</v-icon>
          </v-btn>
         </v-col>
     </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Constants from '@/constants';

export default {
  props: {
    source: String,
  },
  data() {
    return {
      isValid: true,
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
      loginData: {
        userName: '',
        password: '',
      },
    };
  },
  methods: {

    ...mapActions(['login']),

    async handleSubmit() {
      if (this.isValid) {
        const isSuccessful = await this.login({
          userName: this.loginData.userName,
          password: this.loginData.password,
        });

        if (isSuccessful) {
          // Redirect user to Dashboard
          this.$router.push({ name: 'DashboardHome', params: { lang: this.$i18n.locale } });
          this.$eventBus.$emit(this.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
            { successful: true, message: this.$t('successfulLogin') });
        } else if (this.authenticationErrorCode > 0) {
          this.$eventBus.$emit(this.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
            { successful: false, message: this.$t('errorMessages.wrongUserNameAndPasswordMsg') });
        }
      }
    },

    /**
     * Reset password when user click on forgot password link.
     */
    onClickOfForgotPassword(event) {
      console.log('------ Login : On click of forgot password -------', event);
      this.$emit('ForgotPasswordEmitter', true);
    },

  },
  computed: {

    ...mapGetters(['authenticationErrorCode', 'authenticationError']),

    /**
     * Computed function for password rules.
     */
    passwordRules() {
      return [
        (v) => (v && v.length >= 5) || this.$t('userValidations.passwordMustHaveCharacter'),
        (v) => /(?=.*[A-Z])/.test(v) || this.$t('userValidations.passwordMustHaveOneUppercaseChar'),
        (v) => /(?=.*\d)/.test(v) || this.$t('userValidations.passwordMustHaveOneNumber'),
        (v) => /([!@$%])/.test(v) || this.$t('userValidations.passwordMustHaveSpecialChar'),
      ];
    },
    /**
     * Computed function for user Name rules.
     */
    userNameRules() {
      return [
        (value) => !!value || this.$t('userValidations.userNameIsRequired'),
        (value) => (value && value.length >= 3) || this.$t('userValidations.userNameMustHaveCharacter'),
        (value) => (value && value.length <= 30) || this.$t('userValidations.userNameMaxCharacter'),
      ];
    },
  },
};
</script>

<style>
.forgot-text-css {
  color: gray;
  margin-left: 15px;
  font-size: 10px !important;
  font-style: italic;
}

#nav {
  color: red;
  position: realtive;
  background-color: white;
}
</style>
