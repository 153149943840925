<template>
  <div>
    <v-app-bar flat fixed clipped-left prominent app>
      <v-container fill-height style="width: 200px;">
        <v-row class="flex-nowrap" no-gutters>
          <v-col>
            <v-img
              :src="require(isDarkMode ? '@/assets/GHM_Logo_2024_RGB_kurz.svg':
                                         '@/assets/GHM_Logo_2024_RGB_kurz.svg')"
              contain
              height="100"
              width="150"
              position="center"
              class="ma-0 pa-0 mb-3"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
      <v-container fill-height>
        <v-row class="flex-nowrap" no-gutters>
          <v-col>
            <span class="ghmPprojectTitleStyle">{{ $t('ghmProjectTitle') }}</span>
          </v-col>
        </v-row>
      </v-container>
      <v-spacer></v-spacer>
      <Login v-if="!loggedIn" @ForgotPasswordEmitter="forgotPasswordEmitter" />
      <v-spacer></v-spacer>
      <v-divider inset vertical></v-divider>
      <v-container fill-height style="width: 180px">
        <v-row
          class="flex-nowrap"
          no-gutters
          align-content="center"
          justify="center"
          align="center"
        >
          <v-col>
            <span class="darkModeCss">Dark Mode</span>
          </v-col>
        </v-row>
        <v-row
          class="flex-nowrap"
          no-gutters
          align-content="center"
          justify="center"
          align="center">
          <v-col>
            <v-switch
            v-model="isDarkMode" label color="teal" hide-details></v-switch>
          </v-col>
        </v-row>
      </v-container>
      <v-divider v-if="loggedIn" inset vertical></v-divider>
      <v-container v-if="loggedIn" fill-height style="width: 100px">
        <v-row
          class="flex-nowrap"
          no-gutters
          align-content="center"
          justify="center"
          align="center"
        >
          <v-col>
            <v-btn class="ml-4 mr-4" @click="handleLogout" icon>
              <v-icon color="primary" large>mdi-logout</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-divider inset vertical></v-divider>
      <LanguageSwitcher />
    </v-app-bar>
    <v-system-bar
      dark
      fixed
      style="margin-top: 128px; z-index: 99;"
      :color="APP_SYSTEM_BAR_BACKGROUND_COLOR"
      height="60" >
      <v-app-bar-nav-icon v-if="loggedIn" @click.stop="toggleDrawer">
        <v-icon color="white">fas fa-bars</v-icon>
      </v-app-bar-nav-icon>
      <span v-if="loggedIn" class="pageTitle ml-3">{{ $t(getPageTitle)}}</span>
      <v-spacer></v-spacer>
      <span v-if="loggedIn" class="loggedInUserSysBarTealTextCss">
        {{ $t('loggedInAs') }}&nbsp;
        <span
          class="loggedInUserSysBarYellowTextCss"
        >{{ getLoggedInUserData.userName }}</span>
      </span>
    </v-system-bar>
  </div>
</template>
<script>
import Constants from '@/constants';
import Login from '@/components/login/Login.vue';
import LanguageSwitcher from '@/components/app/LanguageSwitcher.vue';
import { mapGetters, mapActions } from 'vuex';

export default {

  props: {
    isNavDrawerOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      APP_BAR_BACKGROUND_COLOR: Constants.APP_BAR_BACKGROUND_COLOR,
      APP_SYSTEM_BAR_BACKGROUND_COLOR:
        Constants.APP_SYSTEM_BAR_BACKGROUND_COLOR,
    };
  },

  components: {
    Login,
    LanguageSwitcher,
  },

  computed: {
    ...mapGetters(['loggedIn', 'getLoggedInUserData', 'getPageTitle', 'getDarkModeState']),

    isNavDrawerOpenOrClose() {
      return this.isNavDrawerOpen;
    },
    /**
     * Dark mode store state
     */
    isDarkMode: {
      get() {
        return this.getDarkModeState;
      },
      set(value) {
        console.log('------- settting dark mode ----- :', value);
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        this.setDarkMode(value);
      },
    },
  },

  methods: {
    ...mapActions(['logout', 'setDarkMode']),

    handleLogout() {
      console.log('****** logout dashboard ***********');
      this.logout();
      this.$router.push({ name: 'Home' });
    },

    toggleDrawer() {
      console.log('======== toggleDrawer ========= ', this.isNavDrawerOpenOrClose);
      this.$emit('ToggleDrawerEmitter', !this.isNavDrawerOpenOrClose);
    },

    /**
     * Reset password when user click on forgot password link.
     */
    forgotPasswordEmitter(event) {
      console.log('------ AppBar : On click of forgot password -------', event);
      this.$emit('ForgotPasswordEmitter', true);
    },

  },

  /**
  watch: {
    isDarkMode() {
      console.log('----------- switch bar chnageds ', this.isDarkMode);
      this.setDarkMode(this.isDarkMode);
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  }, */
};
</script>
<style lang="scss">
// @import "@/styles/Variables.scss";

.loggedInUserSysBarBaseCss {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 20px;
  letter-spacing: 2px;
  word-spacing: 2px;
  font-weight: 600;
  text-decoration: none;
  font-style: normal;
  font-variant: small-caps;
  text-transform: capitalize;
}

.darkModeCss {
  @extend .loggedInUserSysBarBaseCss;
  color: teal;
  font-size: 14px;
}

.loggedInUserSysBarYellowTextCss {
  @extend .loggedInUserSysBarBaseCss;
  color: $app-system-bar-userName-text-color;
}

.loggedInUserSysBarTealTextCss {
  @extend .loggedInUserSysBarBaseCss;
  color: $app-system-bar-userName-label-text-color;
  font-size: 14px;
}

.pageTitle {
  @extend .loggedInUserSysBarBaseCss;
  color: $ghm-dataportal-page-title-color;
}

.menuText {
  color: $app-menu-bar-text-color;
}

.menuIconColor {
  color: $app-menu-bar-text-color;
}

.meuItemCss {
  @extend .loggedInUserSysBarBaseCss;
  height: 70px;
  color: $app-menu-bar-text-color !important;
  &:hover {
    div > * {
      color: $app-menu-bar-text-hover-color !important; //#f58220 // teal
    }
  }
}

.ghmPprojectTitleStyle {
  font-family: "Lucida Sans Un icode", "Lucida Grande", sans-serif;
  font-size: 25px;
  letter-spacing: 2px;
  word-spacing: 2px;
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  font-variant: small-caps;
  text-transform: capitalize;
}

.logoBottomMsgCSs {
  font-size: 12px;
  font-style: italic;
  font-weight: bolder;
}
</style>
