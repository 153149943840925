<template>
  <v-flex>
    <v-card :elevation="15">
      <h1 class="text-left signUp-title">{{ $t('createAnAccount') }}</h1>
      <h4 class="text-left signUp-subTitle">{{ $t('itsQuickAndEasy') }}</h4>
      <v-card-text>
        <v-form v-model="isValid">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                :label="$t('loginUserName')"
                name="username"
                v-model="signUpData.userName"
                prepend-icon="verified_user"
                type="text"
                :disabled="true"
                required
                maxlength="30"
                :rules="userNameRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                :label="$t('loginEmailId')"
                name="email"
                v-model="signUpData.email"
                prepend-icon="email"
                :disabled="true"
                type="text"
                required
                maxlength="100"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                id="signUpPassword"
                :label="$t('loginPassword')"
                name="password"
                v-model="password"
                prepend-icon="lock"
                type="password"
                required
                @change="onChangeOfPassword()"
                :rules="passwordRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                id="signUpConfirmPassword"
                :label="$t('signupConfirmedPassword')"
                name="confirmPassword"
                v-model="confirmPassword"
                prepend-icon="lock"
                type="password"
                required
                :rules="confirmPasswordRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          elevation="23"
          large
          color="primary"
          :disabled="!isValid"
          @click="handleSubmit"
        >Sign Up</v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex';
import Constants from '@/constants';

export default {
  props: {
    userSignUpDataProps: {
      type: Object,
      required: true,
    },
    invitationTokenProps: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isValid: true,
      password: '',
      confirmPassword: '',
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
    };
  },

  computed: {
    signUpData() {
      return {
        userId: this.userSignUpDataProps.userId,
        userName: this.userSignUpDataProps.userName,
        email: this.userSignUpDataProps.email,
      };
    },

    /**
     * Computed function for user Name rules.
     */
    userNameRules() {
      return [
        (value) => !!value || this.$t('userValidations.userNameIsRequired'),
        (value) => (value && value.length >= 3)
          || this.$t('userValidations.userNameMustHaveCharacter'),
        (value) => (value && value.length <= 30)
          || this.$t('userValidations.userNameMaxCharacter'),
      ];
    },

    emailRules() {
      return [
        (value) => !!value || this.$t('userValidations.emailIdIsRequired'),
        (value) => value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) || this.$t('userValidations.invalidEmailAddress'),
        (value) => (value && value.length >= 10)
          || this.$t('userValidations.emailMustHaveCharacter'),
        (value) => (value && value.length <= 100)
          || this.$t('userValidations.emailMaxCharacter'),
      ];
    },

    /**
     * Computed function for password rules.
     */
    passwordRules() {
      return [
        (v) => (v && v.length >= 5)
          || this.$t('userValidations.passwordMustHaveCharacter'),
        (v) => /(?=.*[A-Z])/.test(v)
          || this.$t('userValidations.passwordMustHaveOneUppercaseChar'),
        (v) => /(?=.*\d)/.test(v)
          || this.$t('userValidations.passwordMustHaveOneNumber'),
        (v) => /([!@$%])/.test(v)
          || this.$t('userValidations.passwordMustHaveSpecialChar'),
      ];
    },

    /**
     * Computed function for password rules.
     */
    confirmPasswordRules() {
      return [
        (v) => (v && v.length >= 5)
          || this.$t('userValidations.passwordMustHaveCharacter'),
        (v) => /(?=.*[A-Z])/.test(v)
          || this.$t('userValidations.passwordMustHaveOneUppercaseChar'),
        (v) => /(?=.*\d)/.test(v)
          || this.$t('userValidations.passwordMustHaveOneNumber'),
        (v) => /([!@$%])/.test(v)
          || this.$t('userValidations.passwordMustHaveSpecialChar'),
        (v) => (v && v.localeCompare(this.password) === 0)
          || this.$t('userValidations.confirmPasswordIsNotSameAsPassword'),
      ];
    },
  },

  methods: {
    ...mapActions(['signUp']),

    navigateBackToHome() {
      this.$router.push({ name: 'Home' }, () => {});
    },

    onChangeOfPassword() {
      console.log('---------------- on key press ================');
      this.confirmPassword = '';
    },

    async handleSubmit() {
      // Perform a simple validation that email and password have been typed in
      if (this.isValid) {
        console.log('userName ', this.signUpData);
        console.log(
          '-------- invitationTokenProps ------------ ',
          this.invitationTokenProps,
        );
        try {
          console.log(
            '******** invitationToken 112233444 : ',
            this.invitationTokenProps,
          );
          const userCreatedResponseMsg = await this.signUp({
            signUpData: {
              userId: this.signUpData.userId,
              userName: this.signUpData.userName,
              email: this.signUpData.email,
              password: this.password,
            },
            invitationToken: this.invitationTokenProps,
          });
          console.log('******** Signup reponse dat : ', userCreatedResponseMsg);
          if (typeof userCreatedResponseMsg !== 'undefined') {
            console.log('******* signup data object exist: ', userCreatedResponseMsg);
            // this.$emit('SignUpSuccessfulEvent', false);
            this.$emit('SignUpSuccessfulEvent', userCreatedResponseMsg);
            // this.$router.push({ name: 'Home' });
          }
        } catch (error) {
          console.log('Error occurred while creating new user');
          this.$eventBus.$emit(this.GLOBAL_EVENT_BUS_FOR_SNACK_BAR, {
            successful: false,
            message: 'Error occurred while creating new user',
          });
        }
      }
    },
    /* cancleSigUp() {
      this.resestSignUpData();
      this.$emit('cancelSignUpEmitter', false);
    }, */
    /* signUpSuccess() {
      // this.resestSignUpData();
      this.$emit('signUpUserSuccessful', true);
    }, */
    resestSignUpData() {
      this.signUpData.userName = '';
      this.signUpData.email = '';
      this.signUpData.password = '';
      this.signUpData.confirmPassword = '';
    },
  },
};
</script>

<style>
.signUp-title {
  color: black;
  font-size: 30px;
  font-weight: bold;
  padding: 10px 0 0 10px;
}

.signUp-subTitle {
  color: gray;
  font-size: 18px;
  font-weight: normal;
  padding: 10px 0 0 10px;
}

.signUp-label {
  color: gray;
  font-size: 18px;
  padding-left: 0;
}
</style>
