<template>
  <v-app>
    <AppBar :isNavDrawerOpen="false" @ForgotPasswordEmitter="forgotPasswordEmitter" />
    <v-main>
      <v-container fluid style="margin: 60px">
        <v-row align="center" justify="center" v-if="isError">
          <v-col cols="12" md="6">
            <v-alert type="error">{{ errorMessage }}</v-alert>
          </v-col>
        </v-row>
         <v-row align="center" justify="center" v-if="isUserCreated">
          <v-col cols="12" md="6">
            <v-alert type="success">{{ successMsg }}</v-alert>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" v-if="isUserSignUpInvitation">
          <v-col cols="12" md="6">
          <SignUp
              :userSignUpDataProps="userSignUpData"
              :invitationTokenProps="invitationToken"
              @SignUpSuccessfulEvent="signUpSuccessfulEmitter" />
          </v-col>
        </v-row>
        <v-row align="center" justify="center" v-else-if="isForgotPasswordVisible">
          <v-col cols="12" md="6">
              <ForgotPassword />
          </v-col>
        </v-row>
        <v-row align="center" justify="center" v-else>
          <v-col cols="12" md="6">
            <v-card elevation="12" class="mx-auto" width="80%" shaped>
              <v-card-title>
                <span class="ml-6">{{ $t('homeWelcomeMsg') }}</span>
              </v-card-title>
              <v-card-text>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="pa-2 subheadline mb-1">
                      <span>{{ $t('homePageMsgLine1') }}</span>
                    </v-list-item-title>
                    <v-list-item-title class="pa-2 subheadline mb-1">
                      <span>{{ $t('homePageMsgLine2') }}</span>
                    </v-list-item-title>
                    <v-list-item-title class="pa-2 subheadline mb-1">
                      <span>{{ $t('homePageMsgLine3') }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              :src="require('@/assets/DatabaseHome.png')"
              position="center"
              width="500"
              contain
              height="400"
              class="ma-0 pa-0 mb-n3"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import AppBar from '@/components/app/AppBar.vue';
import AppFooter from '@/components/app/AppFooter.vue';
import { mapActions } from 'vuex';
import SignUp from '@/components/home/SignUp.vue';
import ForgotPassword from '@/components/home/ForgotPassword.vue';

export default {
  components: {
    AppBar,
    AppFooter,
    SignUp,
    ForgotPassword,
  },

  data() {
    return {
      isUserSignUpInvitation: false,
      invitationToken: '',
      isError: false,
      isUserCreated: false,
      successMsg: '',
      errorMessage: '',
      isForgotPasswordVisible: false,
      userSignUpData: {
        userId: '',
        userName: '',
        email: '',
      },
    };
  },

  async created() {
    console.log('---------- Created called ----------- : ', this.$route);
    console.log(
      '----------Param reading from URL ----------- : ',
      this.$route.query.token,
    );
    if (this.$route && this.$route.path) {
      const urlPath = this.$route.path;
      console.log('---------- token exist here ----------- : ');
      const indexOfSignup = urlPath.indexOf('/signup/');
      console.log('---------- indexOfSignup ----------- : ', indexOfSignup);
      if (indexOfSignup !== -1) {
        const invitationTokenFromUrl = urlPath.substring(
          indexOfSignup + 8,
          urlPath.length,
        );
        console.log('---------- invitationTokenFromUrl ----------- : ', invitationTokenFromUrl);
        if (invitationTokenFromUrl) {
          this.isUserSignUpInvitation = true;
          this.invitationToken = invitationTokenFromUrl;
          this.fetchUserFromInvitationLink(invitationTokenFromUrl);
        }
      }
    } else {
      this.isUserSignUpInvitation = false;
    }
  },

  methods: {
    ...mapActions(['findUserFromInvitationToken']),

    /**
     * Fetch user details from invitation token during initalization.
     */
    async fetchUserFromInvitationLink(invitationToken) {
      console.log(
        '------------------fetchUserFromInvitationLink : ',
        invitationToken,
      );
      console.log(
        '------------------this.invitationToken : ',
        this.invitationToken,
      );
      this.isError = false;
      this.isUserCreated = false;
      try {
        if (invitationToken) {
          const userInvitationTokenResponse = await this.findUserFromInvitationToken(
            invitationToken,
          );
          if (
            typeof userInvitationTokenResponse !== 'undefined'
            && 'successful' in userInvitationTokenResponse
            && userInvitationTokenResponse.successful === false
          ) {
            console.log('******* token expired : ');
            this.isUserSignUpInvitation = false;
            this.isError = true;
            this.errorMessage = this.$t(
              `userValidations.${userInvitationTokenResponse.message}`,
            );
          } else {
            console.log(
              '******* user from invite link exist: ',
              userInvitationTokenResponse,
            );
            this.isError = false;
            if ('userId' in userInvitationTokenResponse && userInvitationTokenResponse.userId) {
              console.log(
                '******* user object : ',
                userInvitationTokenResponse,
              );
              this.userSignUpData.userId = userInvitationTokenResponse.userId;
              this.userSignUpData.userName = userInvitationTokenResponse.userName;
              this.userSignUpData.email = userInvitationTokenResponse.email;

              console.log('*******this.userSignUpData : ', this.userSignUpData);
            }
          }
        }
      } catch (error) {
        console.log('Some error occurred while validating invitation token !');
      }
    },

    /**
     * Forgot password emitter
     */
    forgotPasswordEmitter(event) {
      console.log('------ Home : On click of forgotPasswordEmitter 111-------', event);
      if (event) {
        console.log('------ Home : On click of forgotPasswordEmitter 222-------', event);
        this.isForgotPasswordVisible = true;
      }
    },

    /**
     * Event emmitter method gets call when user created successfully.
     */
    signUpSuccessfulEmitter(event) {
      console.log('-----------signUpSuccessfulEmitter --------- : ', event);
      this.isError = false;
      this.isUserCreated = true;
      this.successful = true;
      this.successMsg = this.$t(`userValidations.${event.message}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
</style>
