<template>
  <div class="text-center">
    <v-snackbar
      :color="isSuccessfulOrFailure"
      :value="snackbarVisible"
      :timeout="timeout"
      :multi-line="isMultiline"
      :bottom="true"
      style="z-index: 99999999;"
      :vertical="false">
      {{ snackTextMsg }}
      <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="$emit('update:snackbar', false)">
             <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: {
    multiline: {
      type: Boolean,
      required: true,
    },
    snackbar: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    isSuccess: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      timeout: 5000,
    };
  },
  watch: {
    snackbar() {
      setTimeout(() => { this.$emit('changeVisibilityAfterTimeout'); }, this.timeout);
    },
  },
  computed: {
    isSuccessfulOrFailure() {
      if (this.isSuccess) {
        return 'teal';
      }
      return 'red';
    },
    snackTextMsg() {
      return this.text;
    },
    isMultiline() {
      return this.multiline;
    },
    snackbarVisible() {
      console.log('--------- snackbar snackbarVisible -------------- : ', this.snackbar);
      return this.snackbar;
    },
  },
};
</script>
