import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/components/home/Home.vue';
import i18n from '../plugins/i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '/:lang',
    component: {
      render(c) { return c('router-view'); },
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: 'signup/:id',
        name: 'SignUp',
        component: Home,
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/components/dashboard/Dashboard.vue'),
      },
      {
        path: 'dashboardHome',
        name: 'DashboardHome',
        component: () => import(/* webpackChunkName: "DashboardHome" */ '@/components/dashboard/DashboardHome.vue'),
      },
      {
        path: 'user/home',
        name: 'UserHome',
        component: () => import(/* webpackChunkName: "UserHome" */ '@/components/user/UserHome.vue'),
      },
      {
        path: 'user/uesrQueriesList',
        name: 'UserQueriesList',
        component: () => import(/* webpackChunkName: "UserQueriesList" */ '@/components/user/UserQueriesList.vue'),
      },
      {
        path: 'userQueryDetails',
        name: 'UserQueryDetails',
        component: () => import(/* webpackChunkName: "UserQueryDetails" */ '@/components/user/UserQueryDetails.vue'),
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/components/home/ForgotPassword.vue'),
      },
      {
        path: 'admin/adminDataQueriesList',
        name: 'AdminDataQueriesList',
        component: () => import(/* webpackChunkName: "AdminDataQueriesList" */ '@/components/admin/AdminDataQueriesList.vue'),
      },
      {
        path: 'admin/adminQueryDetails',
        name: 'AdminQueryDetails',
        component: () => import(/* webpackChunkName: "AdminQueryDetails" */ '@/components/admin/AdminQueryDetails.vue'),
      },
      {
        path: 'admin/userManagement',
        name: 'UserManagement',
        component: () => import(/* webpackChunkName: "UserManagement" */ '@/components/admin/UserManagement.vue'),
      },
      {
        path: 'admin/dbconnections',
        name: 'DBConnections',
        component: () => import(/* webpackChunkName: "DBConnections" */ '@/components/admin/DBConnections.vue'),
      },
      {
        path: 'admin/applicationLogs',
        name: 'ApplicationLogs',
        component: () => import(/* webpackChunkName: "ApplicationLogs" */ '@/components/admin/ApplicationLogs.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
