<template>
  <div>
      <AppBar :isNavDrawerOpen="drawer" @ToggleDrawerEmitter="toggleDrawerEmit" />
      <AppNavigationDrawer :isNavDrawerOpen="drawer" v-if="loggedIn" />
      <v-main style="margin-top: 170px;" app>
        <v-container fluid
        style="align-items: flex-start" fill-height justify-start class="px-2 py-0">
        <v-row>
          <v-col>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
      </v-main>
       <AppFooter />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Constants from '@/constants';
import AppBar from '@/components/app/AppBar.vue';
import AppFooter from '@/components/app/AppFooter.vue';
import AppNavigationDrawer from '@/components/app/AppNavigationDrawer.vue';

export default {
  name: 'Dashboard',

  components: {
    AppBar,
    AppFooter,
    AppNavigationDrawer,
  },

  data() {
    return {
      drawer: true,
      GLOBAL_EVENT_LOGOUT_EMITTER_EVENT: Constants.GLOBAL_EVENT_LOGOUT_EMITTER_EVENT,
    };
  },

  created() {
    this.setPageTitleOfThisPage();

    this.$eventBus.$on(
      this.GLOBAL_EVENT_LOGOUT_EMITTER_EVENT,
      this.logoutGlobalEmitter.bind(this),
    );
  },

  beforeDestroy() {
    console.log('*************** Before destroy ********************');
    this.$eventBus.$off(this.GLOBAL_EVENT_LOGOUT_EMITTER_EVENT);
  },

  methods: {
    ...mapActions(['logout', 'setPageTitle']),

    handleLogout() {
      console.log('****** logout dashboard ***********');
      this.logout();
      this.$router.push({
        name: 'Home',
        params: { lang: this.$i18n.locale },
      });
    },

    logoutGlobalEmitter() {
      this.handleLogout();
    },

    setPageTitleOfThisPage() {
      this.setPageTitle('');
    },

    toggleDrawerEmit(event) {
      console.log('------toggeldrawer emitter 11 ', event);
      this.drawer = event;
    },
  },

  computed: {
    ...mapGetters(['loggedIn', 'getLoggedInUserData']),

    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
};
</script>

<style scoped>
.theme--dark.v-application {
  background-color: #181818;
}

.theme--dark.v-card,
.theme--dark.v-data-table,
.theme--dark.v-tabs-items,
.theme--dark.v-tabs .v-tabs-bar {
  background-color: #232323;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #111;
}

::-webkit-scrollbar-thumb {
  background-color: #222;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #444;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.6);
  background-color: #333;
}

::-webkit-scrollbar-track:hover {
  background-color: #292929;
}

.green-circle {
  height: 12px;
  width: 12px;
  background-color: rgb(0, 92, 0);
  border-radius: 50%;
  display: inline-block;
  color: green;
}
</style>
