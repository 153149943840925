<template>
<transition name="fade" mode="out-in" appear>
  <v-navigation-drawer
    dark
    :value="openAndCloseNavDrawer"
    class="elevation-3"
    floating
    expand-on-hover
    :mini-variant.sync="mini"
    style="margin-top: 188px !important;"
    :permanent="openAndCloseNavDrawer"
    app
  >
    <v-list-item style="min-height: 65px" :class="mini ? 'px-2 py-2 cursorPointer': 'px-2 py-2'">
     <v-avatar :color="avtarBackgroundColor" :size="mini ? 40 : 65">
        <span style="font-size: 20px"
        class="white--text headline">{{getUserNameFirstCharForAvatar}}</span>
      </v-avatar>
      <v-list-item-title class="nav-drawer-username-title-text ml-2">
        {{getLoggedInUserData.userName}}
        <v-list-item-subtitle
        class="nav-drawer-username-subtitle-text">
        {{isAdminUser ? '(Admin)' : '(Client)'}}</v-list-item-subtitle>
      </v-list-item-title>
      <!-- <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn> -->
    </v-list-item>
    <v-list-item :class="mini ? 'cursorPointer': ''">
      <!-- <v-btn class="ml-n2" icon @click.stop="mini = !mini" v-if="mini" >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn> -->
      <v-list-item-subtitle class="nav-drawer-email-subtitle-text">
        <v-icon small>mdi-at</v-icon>&nbsp;{{ getLoggedInUserData.emailId }}
      </v-list-item-subtitle>
    </v-list-item>

    <v-divider></v-divider>

    <v-list v-for="item in filterMenuListAsPerRole" :key="item.title">
      <v-list-item class="meuItemCss px-2" :to="{ name: item.component}"
      active-class="test" @click.stop="onClickOfNavItem(item.id)">
        <v-list-item-icon>
          <v-icon :color="isNavItemSelected(item.id)
           ? appMenuBarTextHoverColor :
           APP_NAVIGATION_DRAWER_TEXT_AND_ICON_COLOR" large>
            {{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
          :class="isNavItemSelected(item.id) ?
          'nav-drawer-title-text nav-item-selected-menu-text-color':'nav-drawer-title-text'">
          {{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="isNavItemSelected(item.id)">
          <v-icon :color="isNavItemSelected(item.id)
           ? appMenuBarTextHoverColor :
           APP_NAVIGATION_DRAWER_TEXT_AND_ICON_COLOR">mdi-chevron-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</transition>
</template>
<script>
import Constants from '@/constants';
import { mapGetters } from 'vuex';
import ColorVariable from '@/styles/Variables.scss';

export default {
  props: {
    isNavDrawerOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedMenuNumber: 1,
      avtarBackgroundColor: ColorVariable.avtarBackgroundColor,
      appMenuBarTextHoverColor: ColorVariable.appMenuBarTextHoverColor,
      mini: true,
      menuItems: [
        {
          id: 1,
          title: 'app.pageTitle.dashbaordHome',
          icon: 'mdi-home',
          component: 'DashboardHome',
          role: [Constants.APPLICATION_ROLE_USER],
        },
        {
          id: 2,
          title: 'app.menu.queriesList',
          icon: 'mdi-database-search',
          component: 'UserQueriesList',
          role: [Constants.APPLICATION_ROLE_USER],
        },
        {
          id: 1,
          title: 'app.pageTitle.dashbaordHome',
          icon: 'mdi-home',
          component: 'DashboardHome',
          role: [Constants.APPLICATION_ROLE_ADMIN],
        },
        {
          id: 2,
          title: 'app.menu.dbConnections',
          icon: 'mdi-server',
          component: 'DBConnections',
          role: [Constants.APPLICATION_ROLE_ADMIN],
        },
        {
          id: 3,
          title: 'app.menu.manageQueries',
          icon: 'mdi-database-search',
          component: 'AdminDataQueriesList',
          role: [Constants.APPLICATION_ROLE_ADMIN],
        },
        {
          id: 4,
          title: 'app.menu.userManagement',
          icon: 'mdi-account-multiple',
          component: 'UserManagement',
          role: [Constants.APPLICATION_ROLE_ADMIN],
        },
        {
          id: 5,
          title: 'app.menu.logs',
          icon: 'mdi-history',
          component: 'ApplicationLogs',
          role: [Constants.APPLICATION_ROLE_ADMIN],
        },
      ],
      APP_NAVIGATION_DRAWER_TEXT_AND_ICON_COLOR:
        Constants.APP_NAVIGATION_DRAWER_TEXT_AND_ICON_COLOR,
    };
  },
  methods: {

    onClickOfNavItem(selectedItemId) {
      this.selectedMenuNumber = selectedItemId;
    },

    isNavItemSelected(itemId) {
      return itemId === this.selectedMenuNumber;
    },

    checkIfRoleExist(menuItem) {
      for (let i = 0; i < this.getLoggedInUserRoles.length; i += 1) {
        if ((menuItem.role).indexOf(this.getLoggedInUserRoles[i]) !== -1) {
          return true;
        }
      }
      return false;
    },

  },
  computed: {
    ...mapGetters(['loggedIn', 'getLoggedInUserData', 'getLoggedInUserRoles', 'isAdminUser']),

    openAndCloseNavDrawer() {
      return this.isNavDrawerOpen;
    },

    /**
     * Filter menu based on user roles.
     */
    filterMenuListAsPerRole() {
      if (this.menuItems) {
        console.log('----------- this.getLoggedInUserRoles ----------- : ', this.getLoggedInUserRoles);
        if (this.getLoggedInUserRoles && this.getLoggedInUserRoles.length > 0) {
          console.log('---what is my value--- ');
          return (this.menuItems)
            .filter((menuItem) => this.checkIfRoleExist(menuItem));
        }
        return [];
      }
      return [];
    },

    /**
     * Get username first character from username to show this in avatar icon.
     */
    getUserNameFirstCharForAvatar() {
      if (this.getLoggedInUserData.userName) {
        return this.getLoggedInUserData.userName.substring(0, 1);
      }
      return '';
    },
  },
};
</script>
<style scoped lang="scss">
// @import "@/styles/Variables.scss";

.cursorPointer {
  cursor: pointer;
}

.loggedInUserSysBarBaseCss {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 600;
  text-decoration: none;
  font-style: normal;
  font-variant: small-caps;
  text-transform: capitalize;
}

.meuItemCss {
  @extend .loggedInUserSysBarBaseCss;
  height: 70px !important;
  color: $app-menu-bar-text-color !important;
  &:hover {
    div > * {
      color: $app-menu-bar-text-hover-color !important;
    }
  }
}

.nav-drawer-title-text {
  font-size: 17px !important;
}

.nav-drawer-username-title-text {
  @extend .loggedInUserSysBarBaseCss;
  font-size: 22px !important;
  font-weight: 800 !important;
  color: yellow !important;
}

.nav-drawer-username-subtitle-text {
  font-size: 16px !important;
  color: peru !important;
}

.nav-drawer-email-subtitle-text {
  font-size: 14px !important;
  color: cornflowerblue !important;
}

.v-list-item__icon {
  align-self: center !important;
}

.v-list .v-list-item--active {
  // background-color: #363636 !important;
  color: #f58220 !important;
}

.nav-item-selected-menu-text-color {
  color: $app-menu-bar-text-hover-color !important;
}

/*
.v-list-item--link:active:before {
    background-color: red !important;
    bottom: 0;
    content: url('https://www.nfpa.org/~/media/Images/icons/arrow_icon_blue_4x9.gif');
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    display: block;
}

.v-list-item--link:active:after {
    background-color: blue !important;
    bottom: 0;
    content: url('https://www.nfpa.org/~/media/Images/icons/arrow_icon_blue_4x9.gif');
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    display: block;
}

.test {
     background-image: url('https://www.nfpa.org/~/media/Images/icons/arrow_icon_blue_4x9.gif');
     display: block;
}

*/
.theme--dark.v-list-item--active:hover::before,
.theme--dark.v-list-item--active::before {
  opacity: 0;
}
</style>
