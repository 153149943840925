<template>
  <!-- <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
    </select>
  </div>-->
  <v-container fill-height style="width: 100px; z-index: 99">
    <v-row
      class="flex-nowrap"
      no-gutters
      align-content="center"
      justify="center"
      align="center"
    >
      <v-col>
        <v-menu offset-y transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on" href="#" class="flex items-center">
              <v-row class="flex-nowrap" no-gutters>
                <v-col class="ml-1 mt-2">
                  <!-- <lang-flag :iso="$i18n.locale" /> -->
                  <v-img
                    :src="
                      require($i18n.locale === 'de'
                        ? '@/assets/de.svg'
                        : '@/assets/gb.svg')
                    "
                    width="35"
                    height="25"
                    contain
                    position="center"
                    class="ma-0 pa-0 mb-n3"
                  ></v-img>
                </v-col>
                <v-col>
                  <span class="ml-2 languageSelectionStyle">{{
                    $i18n.locale.toUpperCase()
                  }}</span>
                </v-col>
                <v-col>
                  <v-icon color="blue-grey darken-2">{{
                    expandMoreIcon
                  }}</v-icon>
                </v-col>
              </v-row>
            </button>
          </template>
          <v-list>
            <v-list-item
              v-for="(language, i) in this.parseLanguageStrToObject"
              :key="i"
              @click.prevent="setLocale(language.langShort)"
            >
              <v-list-item-title>
                <!-- <lang-flag :iso="language.iconIso" /> -->
                <v-row class="flex-nowrap" no-gutters>
                  <v-col>
                    <v-img
                      :src="
                        require(language.iconIso === 'de'
                          ? '@/assets/de.svg'
                          : '@/assets/gb.svg')
                      "
                      width="30"
                      height="20"
                      contain
                      position="center"
                      class="ma-0 pa-0 mb-n3"
                    ></v-img>
                  </v-col>
                  <v-col>
                    <span class="ml-2">{{ language.langDescription }}</span>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      expandMoreIcon: 'expand_more',
      allAvailableLanguages: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(
        ';',
      ),
    };
  },

  computed: {
    parseLanguageStrToObject() {
      const languageObjectArray = [];
      this.allAvailableLanguages.forEach((element) => {
        languageObjectArray.push(JSON.parse(element));
      });
      return languageObjectArray;
    },
  },

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push({
        params: { lang: locale },
      });
    },
  },
};
</script>

<style scoped>
.languageSelectionStyle {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 15px;
  letter-spacing: 2px;
  word-spacing: 2px;
  color: teal;
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  font-variant: small-caps;
  text-transform: capitalize;
}
</style>
