import Constant from '@/constants';
import ApiService from '@/services/ApiService';
import TokenService from '@/services/TokenService';

/**
 * Login Service for Login related REST Calls.
 */
const LoginService = {

  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * */
  async login(userName, password) {
    const requestData = {
      method: 'POST',
      url: Constant.GHM_LOGIN_REST_API_URL,
      data: {
        userName,
        password,
      },
    };

    try {
      // await ApiService.setXForwardedForHeader();
      console.log('Fetching IP Address is done');
      const response = await ApiService.customRequest(requestData);
      console.log('*** Login Starting token service : ', response);
      console.log('*** Login Starting token service : ', response.data.access_token);
      if (response && response.data) {
        // TokenService.saveToken(response.data.access_token);
        // TokenService.saveRefreshToken(response.data.refresh_token);
        ApiService.setAxiosHeader(response.data.access_token, response.data.ipAddress);

        // ApiService.mount401Interceptor();
        console.log(' *** I am here 1 **** ');
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Error occoured while validating username and password', error);
    }
    return {};
  },

  /**
   * Get backend build version properties for admin.
   *
   * @returns build properties
   * */
  async getBuildVersionInfo() {
    const requestData = {
      method: 'GET',
      url: Constant.GHM_ADMIN_GET_BACKEND_BUILD_INFORMATION_API_URL,
    };

    try {
      console.log('Fetching Backend Build version information is done');
      const response = await ApiService.customRequest(requestData);
      console.log('*** Build information response : ', response);
      console.log('*** Login Starting token service : ', response.data);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Error occoured while fetching build information', error);
    }
    return {};
  },

  /**
   * reset user password.
   *
   * @returns true or false
   * */
  async resetPassword(userNameOrEmailId, lang) {
    console.log('**** Reset Password Response : userNameOrEmailId: ', userNameOrEmailId, ' : ', lang);
    const requestData = {
      method: 'POST',
      url: Constant.GHM_ADMIN_GET_USER_RESET_PASSWORD_API_URL,
      data: { userNameOrEmailId, lang },
    };

    try {
      await ApiService.setXForwardedForHeader();
      const response = await ApiService.customRequest(requestData);
      console.log('**** Reset Password Response : Reset password : ', response.data);
      return response.data;
    } catch (error) {
      console.error('Error occurred while resetting password', error);
    }
    return {};
  },

  /**
     * Logout the current user by removing the token from storage.
     *
     * Will also remove `Authorization Bearer <token>` header from future requests.
    * */
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    console.log('****** logout login service ***********');
    TokenService.removeDataPortalStorage();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();

    // NOTE: Again, we'll cover the 401 Interceptor a bit later.
    // ApiService.unmount401Interceptor();
  },

  /* *
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * */
  async signUp(signUpData, invitationToken) {
    console.log('**** Signup data : ', signUpData);
    console.log('**** token : ', invitationToken);
    const requestData = {
      method: 'PUT',
      url: `${Constant.GHM_ADMIN_GET_USER_SIGNUP_API_URL}/${invitationToken}`,
      data: signUpData,
    };

    try {
      const response = await ApiService.customRequest(requestData);
      console.log('**** response create user*** 123: ', response.data);
      return response.data;
    } catch (error) {
      console.log('Error occoured while fetching login data');
    }
    return {};
  },

  /**
   * Get user details from invitation link.
   *
   * @param userToken
   * @returns userDetails
   * */
  async findUserFromInvitationToken(invitationToken) {
    console.log('**** findUserFromInvitationToken  : ', invitationToken);
    const requestData = {
      method: 'GET',
      url: `${Constant.GHM_ADMIN_GET_USER_DETAILS_FROM_INVITE_LINK_API_URL}/${invitationToken}`,
    };

    try {
      const response = await ApiService.customRequest(requestData);
      console.log('**** User details from Invitation link : ', response.data);
      return response.data;
    } catch (error) {
      console.log('Error while getting user details from invitation link');
    }
    return {};
  },

};

export default LoginService;

export { LoginService };
