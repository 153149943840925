/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import AdminService from '@/services/admin/AdminService';

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
  /**
   * Get list of all available application roles.
   */
  async findAllRoles({ commit }) {
    console.log('------------Admin: Action: findAllRoles-----------');
    commit('loadingRequest');
    try {
      const adminGetAllAvailableRolesList = await AdminService.findAllRoles();
      commit('unLoadingRequest');
      if (typeof adminGetAllAvailableRolesList !== 'undefined') {
        return adminGetAllAvailableRolesList;
      }
      return {};
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return {};
    }
  },

  /**
   * Get list of all available application roles for users.
   */
  async findAllRolesForUser({ commit }) {
    console.log('------------Admin: Action: findAllRolesForUser-----------');
    commit('loadingRequest');
    try {
      const adminGetAllAvailableRolesForUserList = await AdminService.findAllRolesForUser();
      commit('unLoadingRequest');
      if (typeof adminGetAllAvailableRolesForUserList !== 'undefined') {
        return adminGetAllAvailableRolesForUserList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Get list of all available data sources for query.
   */
  async fetchAllDataSourcesList({ commit }) {
    console.log('------------Admin: Action: fetchAllAvailableDataSourcesList-----------');
    commit('loadingRequest');
    try {
      const adminGetAllAvailableDataSourcesList = await AdminService
        .fetchAllAvailableDataSourcesList();
      commit('unLoadingRequest');
      if (adminGetAllAvailableDataSourcesList) {
        return adminGetAllAvailableDataSourcesList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Admin: add application role by Admin.
   *
   */
  async addApplicationRole({ commit }, { roleName }) {
    console.log('------------Admin Store: Action: addApplicationRole-----------');
    commit('loadingRequest');
    commit('setApplicationError', { errorCode: 0, errorMessage: '' });
    try {
      const addApplicationRoleResponse = await AdminService.addApplicationRole(roleName);
      console.log('------------Admin Store: Action: addApplicationRole Response-----------: ', addApplicationRoleResponse);
      commit('unLoadingRequest');
      if (addApplicationRoleResponse) {
        return addApplicationRoleResponse;
      }
      return null;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        console.error('Admin Store Error Catch: adApplicationRole >> ', e.errorCode, ' : ', e.message);
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return null;
    }
  },

  /**
   * Admin: add use by Admin.
   *
   */
  async addNewUser({ commit }, user) {
    console.log('------------Admin Store: Action: addNewUser-----------', user);
    commit('loadingRequest');
    commit('setApplicationError', { errorCode: 0, errorMessage: '' });
    try {
      const addNewUserResponse = await AdminService.addNewUser(user);
      console.log('------------Admin Store: Action: addNewUser Response-----------: ', addNewUserResponse);
      commit('unLoadingRequest');
      if (addNewUserResponse) {
        return addNewUserResponse;
      }
      return null;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        console.error('Admin Store Error Catch: addNewUser >> ', e.errorCode, ' : ', e.message);
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return null;
    }
  },

  /**
   * Admin: add data query by Admin.
   *
   */
  async addDataQuery({ commit }, dataQueryItem) {
    console.log('------------Admin Store: Action: addNewDataQuery-----------', dataQueryItem);
    commit('loadingRequest');
    commit('setApplicationError', { errorCode: 0, errorMessage: '' });
    try {
      const addNewDataQueryResponse = await AdminService.addNewDataQuery(dataQueryItem);
      console.log('------------Admin Store: Action: addNewDataQuery Response-----------: ', addNewDataQueryResponse);
      commit('unLoadingRequest');
      if (addNewDataQueryResponse) {
        return addNewDataQueryResponse;
      }
      return null;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        console.error('Admin Store Error Catch: addNewDataQuery >> ', e.errorCode, ' : ', e.message);
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return null;
    }
  },

  /**
   * Admin: Upate user by Admin.
   *
   */
  async updateUser({ commit }, user) {
    console.log('------------Admin Store: Action: updateUser-----------', user);
    commit('loadingRequest');
    commit('setApplicationError', { errorCode: 0, errorMessage: '' });
    try {
      const updateUserResponse = await AdminService.updateUser(user);
      console.log('------------Admin Store: Action: updateNewUser Response-----------: ', updateUserResponse);
      commit('unLoadingRequest');
      if (updateUserResponse) {
        return updateUserResponse;
      }
      return null;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        console.error('Admin Store Error Catch: updateUser >> ', e.errorCode, ' : ', e.message);
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return null;
    }
  },

  /**
   * Admin: Upate Data Query by Admin.
   *
   */
  async updateDataQuery({ commit }, dataQueryItem) {
    console.log('------------Admin Store: Action: updateDataQuery-----------', dataQueryItem);
    commit('loadingRequest');
    commit('setApplicationError', { errorCode: 0, errorMessage: '' });
    try {
      const updateDataQueryResponse = await AdminService.updateDataQuery(dataQueryItem);
      console.log('------------Admin Store: Action: updateDataQuery Response-----------: ', updateDataQueryResponse);
      commit('unLoadingRequest');
      if (updateDataQueryResponse) {
        return updateDataQueryResponse;
      }
      return null;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        console.error('Admin Store Error Catch: updateDataQuery >> ', e.errorCode, ' : ', e.message);
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return null;
    }
  },

  /**
   * Admin: Update application role by Admin.
   *
   */
  async updateApplicationRole({ commit }, { id, roleName }) {
    console.log('------------Admin Store: Action: updateApplicationRole-----------');
    commit('loadingRequest');
    try {
      const updateApplicationRoleResponse = await AdminService.updateApplicationRole(id, roleName);
      console.log('------------Admin Store: Action: updateApplicationRole Response-----------: ', updateApplicationRoleResponse);
      commit('unLoadingRequest');
      if (typeof updateApplicationRoleResponse !== 'undefined') {
        return updateApplicationRoleResponse;
      }
      return {};
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return {};
    }
  },

  /**
   * Admin: delete application role by Admin.
   *
   */
  async deleteApplicationRole({ commit }, id) {
    console.log('------------Admin Store: Action: deleteApplicationRole-----------', id);
    commit('loadingRequest');
    commit('setApplicationError', { errorCode: 0, errorMessage: '' });
    try {
      const deleteApplicationRoleResponse = await AdminService.deleteApplicationRole(id);
      console.log('------------Admin Store: Action: deleteApplicationRole Response-----------: ', deleteApplicationRoleResponse);
      commit('unLoadingRequest');
      if (deleteApplicationRoleResponse) {
        return deleteApplicationRoleResponse;
      }
      return null;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return null;
    }
  },

  /**
   * Admin: delete User by Admin.
   *
   */
  async deleteUserByAdmin({ commit }, id) {
    console.log('------------Admin Store: Action: deleteUserByAdmin-----------', id);
    commit('loadingRequest');
    commit('setApplicationError', { errorCode: 0, errorMessage: '' });
    try {
      const deleteUserResponse = await AdminService.deleteUserByAdmin(id);
      console.log('------------Admin Store: Action: deleteUserResponse Response-----------: ', deleteUserResponse);
      commit('unLoadingRequest');
      if (deleteUserResponse) {
        return deleteUserResponse;
      }
      return null;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return null;
    }
  },

  /**
   * Admin: delete Data Query by Admin.
   *
   */
  async deleteDataQueryByAdmin({ commit }, id) {
    console.log('------------Admin Store: Action: deleteDataQueryByAdmin-----------', id);
    commit('loadingRequest');
    commit('setApplicationError', { errorCode: 0, errorMessage: '' });
    try {
      const deleteDataQueryResponse = await AdminService.deleteDataQueryByAdmin(id);
      console.log('------------Admin Store: Action: deleteDataQueryResponse Response-----------: ', deleteDataQueryResponse);
      commit('unLoadingRequest');
      if (deleteDataQueryResponse) {
        return deleteDataQueryResponse;
      }
      return null;
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('setApplicationError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return null;
    }
  },

  /**
   * Get list of all available users.
   */
  async findAllUsersForAdmin({ commit }) {
    console.log('------------Admin: Action: findAllUsersForAdmin-----------');
    commit('loadingRequest');
    try {
      const adminGetAllAvailableUsersList = await AdminService.findAllUsersForAdmin();
      commit('unLoadingRequest');
      if (adminGetAllAvailableUsersList) {
        return adminGetAllAvailableUsersList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Get list of all available mssql datasources list.
   */
  async findDBDatasourceDetailList({ commit }) {
    console.log('------------Admin: Action: findDBDatasourceDetailList-----------');
    commit('loadingRequest');
    try {
      const adminGetAllDatasourceDetailsList = await AdminService.findDBDatasourceDetailList();
      commit('unLoadingRequest');
      if (adminGetAllDatasourceDetailsList) {
        return adminGetAllDatasourceDetailsList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Get list of all database logs
   */
  async findAllApplicationLogs({ commit }, paginationData) {
    console.log('------------ Admin: Action: findAllApplicationLogs -----------', paginationData);
    commit('loadingRequest');
    try {
      const adminGetAllAppplicationLogList = await AdminService
        .findAllApplicationLogs(paginationData);
      commit('unLoadingRequest');
      if (adminGetAllAppplicationLogList) {
        return adminGetAllAppplicationLogList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Get total count for application logs
   */
  async findTotalCountForApplicationLogs({ commit }) {
    console.log('------------ Admin: Action: findTotalCountForApplicationLogs -----------');
    commit('loadingRequest');
    try {
      const adminGetTotalCount = await AdminService.findTotalCountForApplicationLogs();
      commit('unLoadingRequest');
      if (adminGetTotalCount) {
        return adminGetTotalCount;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Get list of all data queries for admin.
   */
  async findAllDataQueriesList({ commit }) {
    console.log('------------Admin: Action: findAllDataQueriesList-----------');
    commit('loadingRequest');
    try {
      const adminGetAllDataQueriesList = await AdminService.findAllDataQueriesList();
      commit('unLoadingRequest');
      if (adminGetAllDataQueriesList) {
        return adminGetAllDataQueriesList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Get list of data query details for admin.
   */
  async findQueryDetailForAdmin({ commit }, dataQueryId) {
    console.log('------------Admin: Action: findQueryDetailForAdmin-----------', dataQueryId);
    commit('loadingRequest');
    try {
      const adminGetDataQueryDetailList = await AdminService.findQueryDetailForAdmin(dataQueryId);
      commit('unLoadingRequest');
      if (adminGetDataQueryDetailList) {
        return adminGetDataQueryDetailList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Fetch data from rest api.
   */
  async fetchPaginationMetaData({ commit }, { restUrlParam, dataQueryIdForApi }) {
    console.log('------------Admin: Action: fetchPaginationMetaData-----------', restUrlParam, ' : ', dataQueryIdForApi);
    commit('loadingRequest');
    try {
      const paginationMetaData = await
      AdminService.fetchPaginationMetaData(restUrlParam, dataQueryIdForApi);
      commit('unLoadingRequest');
      if (paginationMetaData) {
        return paginationMetaData;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
