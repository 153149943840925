/* eslint no-shadow: ["error", { "allow": ["state"] }] */

// import router from '@/router';
import { QueryService } from '@/services/user/QueryService';

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {

  /**
   * Get list of data query details for User.
   */
  async findDataQueryListForUser({ commit }) {
    console.log('------------Query: Action: findDataQueryListForUser-----------');
    commit('loadingRequest');
    try {
      const userDataQueryList = await QueryService.findDataQueryListForUser();
      commit('unLoadingRequest');
      if (userDataQueryList) {
        return userDataQueryList;
      }
      return [];
    } catch (e) {
      console.log('---------------findDataQueryListForUser Query.Store ------------------------', e);
      commit('unLoadingRequest');
      if (e instanceof Error) {
        console.log('---------------findDataQueryListForUser :------------------------', e);
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Fetch next or previous items from server.
   */
  async fetchNextOrPreviousItems({ commit }, { nextOrPreviousUrl, dataQueryIdParameter }) {
    console.log('------------Query: Action: fetchNextOrPreviousItems-----------');
    commit('loadingRequest');
    try {
      const userDataQueryList = await QueryService
        .fetchNextOrPreviousItems(nextOrPreviousUrl, dataQueryIdParameter);
      commit('unLoadingRequest');
      if (userDataQueryList) {
        return userDataQueryList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

  /**
   * Get list of data query details for User.
   */
  async getUngerboeckBaseUrlsList({ commit }) {
    console.log('------------Admin: Action: getUngerboeckBaseUrlsList-----------');
    commit('loadingRequest');
    try {
      const baseUrlList = await QueryService.fetchAllUngerboeckBaseUrlsList();
      commit('unLoadingRequest');
      if (baseUrlList) {
        return baseUrlList;
      }
      return [];
    } catch (e) {
      commit('unLoadingRequest');
      if (e instanceof Error) {
        commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
      }
      return [];
    }
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
