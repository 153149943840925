import Vue from 'vue';
import moment from 'moment';
import i18n from '@/plugins/i18n';
import Constants from '@/constants';

/**
 * Date vMMue filter. This filter format the date to DD.MM.YYYY hh:mm format.
 */
Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
  }
  return value;
});

/**
 * Date vMMue filter. This filter format the date to DD.MM.YYYY hh:mm format.
 */
Vue.filter('format_Date_dd_mm_yyyy', (value) => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
  return value;
});

/**
 * User type filter. This filter replace ROLE_ADMIN & ROLE_USER
 * to Administrator & User respectively.
 */
Vue.filter('userTypeFilter', (value) => {
  if (value) {
    if (value === Constants.APPLICATION_ROLE_ADMIN) {
      return i18n.t('datatable.tableHeaders.administrator');
    } if (value === Constants.APPLICATION_ROLE_USER) {
      return i18n.t('datatable.tableHeaders.user');
    }
  }
  return value;
});
