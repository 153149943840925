import ApiService from '@/services/ApiService';
import Constant from '@/constants';

/**
 * Query Service for query related REST Calls.
 */
const QueryService = {

  /**
   * Get all application data queries list for user.
   *
   * @returns Page object for role list.
   *
   * */
  async findDataQueryListForUser() {
    console.log('------------- findDataQueryListForUser --------------');
    const requestData = {
      method: 'GET',
      url: Constant.GHM_USER_FIND_ALL_DATA_QUERIES_REST_API_URL,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin findDataQueryListForUser >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching data queries list for users', error);
    }
    return [];
  },

  /**
   * Fetch next or previous items from server.
   *
   * @returns Page object for role list.
   * */
  async fetchNextOrPreviousItems(nextOrPreviousUrl, dataQueryIdParameter) {
    console.log('------------- QueryService : fetchNextOrPreviousItems --------------');
    const requestData = {
      method: 'GET',
      url: Constant.GHM_UNGERBOECK_API_FETCH_NEXT_OR_PREVIOUS_REST_API_URL,
      params: {
        nextOrPreviousUrl,
        dataQueryIdParameter,
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin fetchNextOrPreviousItems >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching Next/Previous data queries items', error);
    }
    return [];
  },

  /**
   * Get all configured baseurl list for data query.
   *
   * @returns Page object for role list.
   * */
  async fetchAllUngerboeckBaseUrlsList() {
    console.log('------------- fetchAllUngerboeckBaseUrlsList --------------');
    const requestData = {
      method: 'GET',
      url: Constant.GHM_UNGERBOECK_API_FETCH_BASE_URL_REST_API_URL,
      params: {
      },
    };
    try {
      const response = await ApiService.customRequest(requestData);
      console.log('*** Admin fetchAllUngerboeckBaseUrlsList >>> : ', response);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log('Admin: Error while fetching base url', error);
    }
    return [];
  },

};

export default QueryService;

export { QueryService };
