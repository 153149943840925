var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"width":"100px","z-index":"99"},attrs:{"fill-height":""}},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":"","align-content":"center","justify":"center","align":"center"}},[_c('v-col',[_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"flex items-center",attrs:{"href":"#"}},'button',attrs,false),on),[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-1 mt-2"},[_c('v-img',{staticClass:"ma-0 pa-0 mb-n3",attrs:{"src":require(_vm.$i18n.locale === 'de'
                      ? '@/assets/de.svg'
                      : '@/assets/gb.svg'),"width":"35","height":"25","contain":"","position":"center"}})],1),_c('v-col',[_c('span',{staticClass:"ml-2 languageSelectionStyle"},[_vm._v(_vm._s(_vm.$i18n.locale.toUpperCase()))])]),_c('v-col',[_c('v-icon',{attrs:{"color":"blue-grey darken-2"}},[_vm._v(_vm._s(_vm.expandMoreIcon))])],1)],1)],1)]}}])},[_c('v-list',_vm._l((this.parseLanguageStrToObject),function(language,i){return _c('v-list-item',{key:i,on:{"click":function($event){$event.preventDefault();return _vm.setLocale(language.langShort)}}},[_c('v-list-item-title',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-img',{staticClass:"ma-0 pa-0 mb-n3",attrs:{"src":require(language.iconIso === 'de'
                        ? '@/assets/de.svg'
                        : '@/assets/gb.svg'),"width":"30","height":"20","contain":"","position":"center"}})],1),_c('v-col',[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(language.langDescription))])])],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }