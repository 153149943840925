/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import Vue from 'vue';
import Vuex from 'vuex';

// import createPersistedState from 'vuex-persistedstate';
// import * as Cookies from 'js-cookie';
import user from './modules/user/user.store';
import admin from './modules/admin/admin.store';
import query from './modules/user/query.store';

Vue.use(Vuex);

const state = {
  loader: false,
  pageTitle: '',
  isDarkModeState: false,
  buildInformation: {
    packageVersion: process.env.VUE_APP_VERSION || '0',
    packageBuildDate: process.env.VUE_APP_BUILD_DATE || '0',
  },
  applicationError: {
    errorCode: 0,
    errorMessage: '',
  },
};

const getters = {
  loader: () => state.loader,

  getPageTitle: () => state.pageTitle,

  getApplicationError: () => state.applicationError,

  getDarkModeState: () => state.isDarkModeState,

  getBuildInformation: () => state.buildInformation,
};

const mutations = {

  /**
   * Loading request
   * @param {*} state
   */
  loadingRequest(state) {
    state.loader = true;
  },

  setApplicationError(state, { errorCode, errorMessage }) {
    state.applicationError.errorCode = errorCode;
    state.applicationError.errorMessage = errorMessage;
  },

  setDarkMode(state, isDarkModeState) {
    console.log('State : setting dark mode', isDarkModeState);
    state.isDarkModeState = isDarkModeState;
  },

  restoreParentStateFromSessionStore(state, parentState) {
    state.loader = parentState.loader;
    state.pageTitle = parentState.pageTitle;
    state.isDarkModeState = parentState.isDarkModeState;
  },

  /**
   * Unloading request.
   *
   * @param {} state
   */
  unLoadingRequest(state) {
    state.loader = false;
  },

  /**
   * Set page title of selected page.
   *
   * @param {*} state
   * @param {*} pageTitle
   */
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle;
  },

};

const actions = {
  /**
   * Set dark mode.
   *
   * @param {*} param0
   */
  setDarkMode({ commit }, isDarkMode) {
    console.log('Action: set dark mode :', isDarkMode);
    commit('setDarkMode', isDarkMode);
  },

  /**
   * Set page title for selected page.
   *
   * @param {*} param0
   * @param {*} param1
   */
  setPageTitle({ commit }, pageTitle) {
    commit('setPageTitle', pageTitle);
  },

  restoreParentStateFromSessionStore({ commit }, parentStateFromStore) {
    console.log('------start----restoreParentStateFromSessionStore -----: ', parentStateFromStore);
    commit('restoreParentStateFromSessionStore', parentStateFromStore);
    console.log('------end----restoreParentStateFromSessionStore -----: ', state);
  },

};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    user,
    admin,
    query,
  },
  /* plugins: [createPersistedState({
    storage: window.sessionStorage,
    path: ['state'],
  })],
   plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ], */
});
